import React, { useState, useEffect } from "react";
import Toast from "../otherComponents/Toast";
import { purchaseItem, getCountries, getSmsPriceForCountry } from "../../api";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Table,
  Pagination,
  Modal,
  Spinner
} from "react-bootstrap";

const Pricing = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [countries, setCountries] = useState([]); // Ülkeleri tutacak state
  const [selectedCountry, setSelectedCountry] = useState(""); // Seçilen ülkeyi tutacak state
  const [pricingPlans, setPricingPlans] = useState([]); // Planları tutacak state
  const [loading, setLoading] = useState(false); // Fiyatlar

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }

    fetchCountries(); // Ülkeleri sayfa yüklendiğinde çekiyoruz
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await getCountries(); // API'den ülkeleri çekiyoruz
      console.log(response);
      setCountries(response.data); // Ülke listesini güncelle
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const handleCountryChange = async (e) => {
    const selected = e.target.value;
    setSelectedCountry(selected);

    // Seçilen ülkeye göre SMS fiyatını çek
    if (selected) {
      try {
        setLoading(true);
        const requestPayload = {
          Country: parseInt(selected),
        };
        const response = await getSmsPriceForCountry(requestPayload); // Ülkeye göre fiyat endpoint'i
        const smsPrice = response.data.object.pricePerSms; // Fiyatı al

        // Planları güncelle: 10.000 ve 20.000 SMS için
        setPricingPlans([
          { amount: 10000, price: (10000 * smsPrice).toFixed(2) },
          { amount: 20000, price: (15000 * smsPrice).toFixed(2) },
        ]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching SMS price:", error);
        setLoading(false);
      }
    }
  };

  const handlePurchase = async (plan) => {
    setShowToast(false); // Toast'ı kapat ve sıfırla
    setToastMessage("");
    if (isLoggedIn) {
      try {
        const requestPayload = {
          Country: parseInt(selectedCountry),
          Amount: plan.amount,
        };
        console.log(requestPayload);
        var response = await purchaseItem(requestPayload);
        setToastMessage(`API response: ${response.data.message || "Success"}`);
        setShowToast(true);
      } catch (error) {
        console.error("Error fetching messages:", error);
        setToastMessage(`API Error: ${error.message}`);
        setShowToast(true);
      }
    } else {
      // Giriş yapmamış kullanıcıyı register sayfasına yönlendir
      window.location.href = "/register";
    }
  };

  return (
    <div
      className="container my-5 justify-content-center"
      style={{
        marginRight: isLoggedIn ? "200px" : "0px", // isLoggedIn durumuna göre marginRight ayarlanıyor
        marginLeft: isLoggedIn ? "-45px" : "150px", // isLoggedIn durumuna göre marginLeft ayarlanıyor
      }}
    >
      <section className="text-center mb-5">
        <h1 className="display-4 font-weight-bold">Pricing Plans</h1>
        <p className="lead">Choose the best plan for your business needs.</p>
      </section>

      {showToast && (
        <Toast
          message={toastMessage}
          onClose={() => setShowToast(false)} // Toast'ı kapat
          duration={2000} // Toast'ın süresini ayarlayabilirsin
        />
      )}

      {/* Ülke seçim dropdown */}
      <Form.Group controlId="countrySelect">
        <Form.Label style={{ width: "300px", marginLeft: 580 }}>
          Select Country
        </Form.Label>
        <Form.Control
          as="select"
          value={selectedCountry}
          onChange={handleCountryChange}
          style={{ width: "300px", marginLeft: 500 }} // Genişliği px cinsinden ayarlayabilirsiniz
        >
          <option value="">Select a country</option>
          {countries.map((country) => (
            <option key={country.value} value={country.value}>
              {country.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <br></br>
      {loading ? (
        <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
        style={{marginLeft:"640px"}}
      /> // Eğer yükleniyorsa loading mesajı
      ) : (
        <div className="row justify-content-center">
          {pricingPlans.map((plan, index) => (
            <div className="col-md-4 mb-4" key={index}>
              <div className="card shadow-sm">
                <div className="card-body text-center">
                  <h3 className="card-title">{plan.amount} SMS</h3>
                  <h2 className="card-text">${plan.price}</h2>
                  <p className="text-muted">
                    ${(plan.price / plan.amount).toFixed(4)} / SMS
                  </p>
                  <ul className="list-unstyled">
                    <li>✔️ All Operators in the Country Coverage</li>
                    <li>✔️ Flat Rate</li>
                    <li>✔️ Delivery Rate +/- 99.7%</li>
                    <li>✔️ No Expiry Date</li>
                    <li>✔️ Help 24/7</li>
                  </ul>
                  <Button
                    className="btn btn-primary"
                    onClick={() => handlePurchase(plan)}
                  >
                    {isLoggedIn ? "Purchase" : "Contact Us"}
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Pricing;
