import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../otherComponents/Toast";
import {
  getAllCategories,
  createCategory,
  updateCategory,
  deleteCategory,
  getCategories,
} from "../../api";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Table,
  Modal,
} from "react-bootstrap";
import "./CustomerPage.css";


const CategoryPage = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn !== null) {
      isLoggedInFunc();
      fetchCategories();
    }
  }, [isLoggedIn]);

  const isLoggedInFunc = () => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await getCategories();
      console.log(response.data.object);
      setCategories(response.data.object);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleCreateCategory = async () => {
    setShowToast(false); // Toast'ı kapat ve sıfırla
    setToastMessage("");
    try {
      var response = await createCategory({ name: newCategoryName });
      setToastMessage(`API response: ${response.data.message || "Success"}`);
      setShowToast(true);
      setNewCategoryName("");
      setShowCreateModal(false);
      fetchCategories();
    } catch (error) {
      console.error("Error creating category:", error);
      setToastMessage(`API Error: ${error.message}`);
      setShowToast(true);
    }
  };

  const handleUpdateCategory = async (id) => {
    setShowToast(false); // Toast'ı kapat ve sıfırla
    setToastMessage("");
    try {
      var response = await updateCategory({ id: id, name: selectedCategory.name });
      setToastMessage(`API response: ${response.data.message || "Success"}`);
      setShowToast(true);
      setSelectedCategory(null);
      setShowEditModal(false);
      fetchCategories();
    } catch (error) {
      console.error("Error updating category:", error);
      setToastMessage(`API Error: ${error.message}`);
      setShowToast(true);
    }
  };

  const handleDeleteCategory = async (id) => {
    setShowToast(false); // Toast'ı kapat ve sıfırla
    setToastMessage("");
    const confirm = window.confirm(
      "Bu kategoriyi silmek istediğinize emin misiniz?"
    );
    if (!confirm) {
      return; // Kullanıcı işlemi onaylamazsa hiçbir şey yapma
    }
    try {
      var response = await deleteCategory(id);
      setToastMessage(`API response: ${response.data.message || "Success"}`);
      setShowToast(true);
      fetchCategories();
    } catch (error) {
      console.error("Error deleting category:", error);
      setToastMessage(`API Error: ${error.message}`);
      setShowToast(true);
    }
  };

  return (
    <>
      {isLoggedIn && (
        <Container className="mt-5">
          <h2 className="text-center mb-4" style={{ marginRight: "290px" }}>
            Category Management
          </h2>
          {showToast && (
            <Toast
              message={toastMessage}
              onClose={() => setShowToast(false)} // Toast'ı kapat
              duration={2500} // Toast'ın süresini ayarlayabilirsin
            />
          )}

          <Button
            variant="primary"
            onClick={() => setShowCreateModal(true)}
            className="mb-3"
          >
            Create Category
          </Button>

          <Table striped bordered hover className="modern-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(categories) &&
                categories.map((category) => (
                  <tr key={category.id}>
                    <td>{category.name}</td>
                    <td>
                      <Button
                        variant="warning"
                        className="me-2"
                        onClick={() => {
                          setSelectedCategory(category);
                          setShowEditModal(true);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => handleDeleteCategory(category.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          <Modal
            show={showCreateModal}
            onHide={() => {setShowCreateModal(false); setNewCategoryName("")}}
          >
            <Modal.Header closeButton>
              <Modal.Title>Create Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formCategoryNameCreate">
                  <Form.Label>Category Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter category name"
                    value={newCategoryName}
                    onChange={(e) => setNewCategoryName(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {setShowCreateModal(false); setNewCategoryName("");}}
              >
                Close
              </Button>
              <Button variant="primary" onClick={handleCreateCategory}>
                Create Category
              </Button>
            </Modal.Footer>
          </Modal>


          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formCategoryNameEdit">
                  <Form.Label>Category Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter category name"
                    value={selectedCategory ? selectedCategory.name : ""}
                    onChange={(e) =>
                      setSelectedCategory({
                        ...selectedCategory,
                        name: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() =>{ setShowEditModal(false); setSelectedCategory(null)}}
              >
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => handleUpdateCategory(selectedCategory.id)}
              >
                Update Category
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      )}
    </>
  );
};

export default CategoryPage;
