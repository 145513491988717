import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import heroImage from "../../assets/hero-image.png"; // Hero görseli

const Home = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn !== null) {
      isLoggedInFunc();
    }
  }, [isLoggedIn]);

  const isLoggedInFunc = () => {
    if (isLoggedIn) {
      navigate("/customers");
    }
  };

  return (
    <div className="container-fluid p-0">

      {/* Hero Section */}
      <section
        className="hero-section text-center text-dark"
        style={{
          backgroundImage: `url(${heroImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "100px 0",
        }}
      >
        <div className="container">
          <h1 className="display-4 font-weight-bold">
            Reach Your Customers With Text4Reach
          </h1>
          <p className="lead mb-4">
            We provide a reliable and efficient way to send mass text messages
            to your target audience.
          </p>
          <a href="/register" className="btn btn-primary btn-lg px-5 shadow-sm">
            Send Bulk Messages
          </a>
        </div>
      </section>

      {/* Features Section */}
      <section className="row text-center my-5 mx-0 px-5">
        <div className="col-md-4 mb-5">
          <h2>SMS Web Tool</h2>
          <p>Easily send SMS through our web tool. Manage your customer data efficiently.</p>
          <a href="/solutions" className="btn btn-outline-primary">
            Learn more
          </a>
        </div>
        <div className="col-md-4 mb-5">
          <h2>Mail Web Tool</h2>
          <p>Seamlessly integrate Mail Service capabilities into your system.</p>
          <a href="/solutions" className="btn btn-outline-primary">
            Learn more
          </a>
        </div>
        <div className="col-md-4 mb-5">
          <h2>Organized Customer Data</h2>
          <p>Upload your customers data, group them and reach the groups specifically</p>
          <a href="/solutions" className="btn btn-outline-primary">
            Learn more
          </a>
        </div>
      </section>
    </div>
  );
};

export default Home;
