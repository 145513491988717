import React, { useEffect, useState } from 'react';

const Toast = ({ message, onClose, duration = 2000 }) => {
  const [progress, setProgress] = useState(100); // İlerleme barı için başlangıç değeri

  useEffect(() => {
    // İlerleme barını süre boyunca azaltma
    const interval = setInterval(() => {
      setProgress((prev) => (prev > 0 ? prev - 1 : 0));
    }, duration / 100);

    // Belirtilen süre sonunda toast'ı kapat
    const timer = setTimeout(() => {
      onClose();
      message = "";
    }, duration);

    return () => {
      clearTimeout(timer); // Temizleme işlemi
      clearInterval(interval); // İlerleme barı için temizlik
    };
  }, [duration, onClose]);

  if (!message) return null;

  return (
    <div style={toastStyle}>
      {message}
      <div style={{ ...progressBarContainerStyle }}>
        <div style={{ ...progressBarStyle, width: `${progress}%` }} />
      </div>
    </div>
  );
};

// Toast için stil
const toastStyle = {
  position: 'fixed',
  top: '20px',
  right: '20px',
  backgroundColor: '#fff', // Beyaz arka plan
  color: '#007BFF', // Mavi yazı rengi
  padding: '20px 40px', // Padding artırıldı
  borderRadius: '8px', // Kenar yuvarlatma artırıldı
  zIndex: 9999,
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)', // Daha belirgin bir gölge
  opacity: 0.95,
  fontSize: '16px', // Yazı boyutu büyütüldü
  border: '1px solid #007BFF', // Mavi çerçeve
  minWidth: '300px', // Minimum genişlik artırıldı
  maxWidth: '500px', // Maksimum genişlik
};

// İlerleme barı için stil
const progressBarContainerStyle = {
  marginTop: '15px', // Daha geniş mesafe
  height: '8px', // Yükseklik artırıldı
  backgroundColor: '#e0e0e0', // Gri arka plan
  borderRadius: '5px',
  overflow: 'hidden',
};

const progressBarStyle = {
  height: '100%',
  backgroundColor: '#28a745', // Yeşil ilerleme barı
  transition: 'width 0.1s linear',
};

export default Toast;
