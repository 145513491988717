import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Await,
} from "react-router-dom";
import Home from "./components/staticPages/Home";
import Deposit from "./components/depositComponents/DepositPage";
import Solutions from "./components/staticPages/Solutions"
import Pricing from "./components/staticPages/Pricing"
import About from "./components/staticPages/About"
import Help from "./components/staticPages/Help"
import AccountSettings from "./components/userComponents/UserSettings";
import Login from "./components/userComponents/Login";
import Register from "./components/userComponents/Register";
import ForgotPassword from "./components/userComponents/ForgotPassword";
import ForgotPasswordVerify from "./components/userComponents/ForgotPasswordVerify";
import PasswordUpdate from "./components/userComponents/PasswordUpdate";
import EmailVerify from "./components/userComponents/EmailVerify";
import NavigationBar from "./components/otherComponents/NavigationBar"; // NavigationBar'ı ekleyin
import Sidebar from "./components/otherComponents/SideBar"; // Sidebar'ı ekleyin
import CustomerPage from "./components/customerComponents/CustomerPage";
import CategoryPage from "./components/customerComponents/CategoryPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css"; // Global CSS dosyasını import edin
import GroupPage from "./components/customerComponents/GroupPage";
import MessageCreationPage from "./components/messageComponents/CreateMessagePage";
import { useNavigate } from "react-router-dom";
import { waitFor } from "@testing-library/react";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  // Token kontrolü için useEffect
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []); // Sadece ilk yüklemede çalışması için boş bağımlılık dizisi kullanıyoruz

  return (
    <div className="bg-light text-dark min-vh-100">
      <NavigationBar />
      <Sidebar />
      <div
        className="content"
        style={{ marginLeft: isLoggedIn ? "250px" : "0", padding: "20px" }}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/about" element={<About />}/>
          <Route path="/help" element={<Help />}/>
          <Route path="/accountSettings" element={<AccountSettings />}/>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route
            path="/forgotPasswordVerify"
            element={<ForgotPasswordVerify />}
          />
          <Route path="/passwordUpdate" element={<PasswordUpdate />} />
          <Route path="/emailVerify" element={<EmailVerify />} />
          <Route path="/customers" element={<CustomerPage />} />
          <Route path="/categories" element={<CategoryPage />} />
          <Route path="/groups" element={<GroupPage />} />
          <Route path="/messages" element={<MessageCreationPage />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
