import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../otherComponents/Toast";
import { Spinner } from "react-bootstrap"; // Bootstrap Spinner bileşenini ekleyin
import "./CustomerPage.css"
import {
  filterCustomers,
  createCustomer,
  addCustomersToCategory,
  addCustomersToGroup,
  getGroups,
  getCategories,
  getCustomerReferences,
  deleteCustomersFromCategory,
  deleteCustomersFromGroup,
  uploadExcelFile,
  bulkAddCustomersToGroupWithEmail,
  bulkAddCustomersToGroupWithUsername,
  getFilteredCustomerIds,
} from "../../api";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Table,
  Pagination,
  Modal,
} from "react-bootstrap";

const CustomerPage = () => {
  const [customers, setCustomers] = useState([]);
  const [filters, setFilters] = useState({
    searchTerm: "",
    categoryId: "",
    groupId: "",
    deposit: "",
    totalDepositMin: "",
    totalDepositMax: "",
    customerReference: "",
    countryCode: "",
    telephoneApproval: "",
    registrationDateFrom: "",
    registrationDateTo: "",
    lastLoginDateFrom: "",
    lastLoginDateTo: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCustomers, setSelectedCustomers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Daha fazla veri olup olmadığını kontrol etmek için
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [customerCount, setCustomerCount] = useState();
  const [references, setReferences] = useState([]);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [allPageSelected, setAllPageSelected] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [showExcelModal, setShowExcelModal] = useState(false);
  const [showBulkAddWithEmailModal, setBulkAddWithEmailModal] = useState(false);
  const [showBulkAddWithUsernameModal, setBulkAddWithUsernameModal] =
    useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [bulkEmails, setBulkEmails] = useState("");
  const [bulkUsernames, setBulkUsernames] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [scrollLoad, setScrollLoad] = useState(false);


  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    // Scroll event listener ekle
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 &&
        !isLoading &&
        hasMore
      ) {
        // Sayfanın en altına gelindiğinde çalışacak kod
        setScrollLoad(true)
        loadMoreCustomers();
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Scroll event listener'ını component unmount olduğunda temizle
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading, hasMore]);

  const loadMoreCustomers = () => {
    if (hasMore) {
      fetchFilteredCustomersLoad()
    }
  };
  const customersPerPage = 20;

  useEffect(() => {
    if (isLoggedIn !== null) {
      // isLoggedIn durumu değiştiğinde çalışır
      isLoggedInFunc();
      fetchFilteredCustomers();
      fetchCategories();
      fetchGroups();
      fetchReferences();
    }
  }, [isLoggedIn]);

  const isLoggedInFunc = () => {
    if (isLoggedIn === false) {
      navigate("/login");
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await getCategories(); // Kategori API endpoint'inizi kontrol edin
      setCategories(response.data.object);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchReferences = async () => {
    try {
      const response = await getCustomerReferences(); // Müşteri referansları API endpoint'inizi kontrol edin
      setReferences(response.data);
    } catch (error) {
      console.error("Error fetching references:", error);
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await getGroups(); // Grup API endpoint'inizi kontrol edin
      setGroups(response.data.object);
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };
  const fetchFilteredCustomers = async () => {
    try {
      const filterPayload = {
        searchTerm: filters.searchTerm || null,
        categoryId: filters.categoryId ? parseInt(filters.categoryId) : null,
        groupId: filters.groupId ? parseInt(filters.groupId) : null,
        deposit: filters.deposit === "" ? null : filters.deposit === "true",
        totalDepositMin: filters.totalDepositMin
          ? parseFloat(filters.totalDepositMin)
          : null,
        totalDepositMax: filters.totalDepositMax
          ? parseFloat(filters.totalDepositMax)
          : null,
        customerReference: filters.customerReference
          ? parseInt(filters.customerReference)
          : null,
        countryCode: filters.countryCode || null,
        telephoneApproval:
          filters.telephoneApproval === ""
            ? null
            : filters.telephoneApproval === "true",
        registrationDateFrom: filters.registrationDateFrom
          ? new Date(filters.registrationDateFrom).toISOString()
          : null,
        registrationDateTo: filters.registrationDateTo
          ? new Date(filters.registrationDateTo).toISOString()
          : null,
        lastLoginDateFrom: filters.lastLoginDateFrom
          ? new Date(filters.lastLoginDateFrom).toISOString()
          : null,
        lastLoginDateTo: filters.lastLoginDateTo
          ? new Date(filters.lastLoginDateTo).toISOString()
          : null,
        pageSize: customersPerPage,
        pageNumber: 1,
      };

      const response = await filterCustomers(filterPayload);
      console.log(response.data);
      const newCustomers = response.data.object;

      setCustomers(newCustomers);

      setCustomerCount(response.data.totalRecords);
      setHasMore(newCustomers.length > 0);
      setCurrentPage((prevPage) => prevPage + 1); // Sayfayı artır

    } catch (error) {
      console.error("Error filtering customers:", error);
    }finally{
    }


  };

  const fetchFilteredCustomersLoad = async () => {

    setIsLoading(true);

    try {
      const filterPayload = {
        searchTerm: filters.searchTerm || null,
        categoryId: filters.categoryId ? parseInt(filters.categoryId) : null,
        groupId: filters.groupId ? parseInt(filters.groupId) : null,
        deposit: filters.deposit === "" ? null : filters.deposit === "true",
        totalDepositMin: filters.totalDepositMin
          ? parseFloat(filters.totalDepositMin)
          : null,
        totalDepositMax: filters.totalDepositMax
          ? parseFloat(filters.totalDepositMax)
          : null,
        customerReference: filters.customerReference
          ? parseInt(filters.customerReference)
          : null,
        countryCode: filters.countryCode || null,
        telephoneApproval:
          filters.telephoneApproval === ""
            ? null
            : filters.telephoneApproval === "true",
        registrationDateFrom: filters.registrationDateFrom
          ? new Date(filters.registrationDateFrom).toISOString()
          : null,
        registrationDateTo: filters.registrationDateTo
          ? new Date(filters.registrationDateTo).toISOString()
          : null,
        lastLoginDateFrom: filters.lastLoginDateFrom
          ? new Date(filters.lastLoginDateFrom).toISOString()
          : null,
        lastLoginDateTo: filters.lastLoginDateTo
          ? new Date(filters.lastLoginDateTo).toISOString()
          : null,
        pageSize: customersPerPage,
        pageNumber: currentPage,
      };

      const response = await filterCustomers(filterPayload);
      console.log(response.data);
      const newCustomers = response.data.object;

      setCustomers((prevCustomers) => [...prevCustomers, ...newCustomers]);

      setCustomerCount(response.data.totalRecords);
      setHasMore(newCustomers.length > 0);
      setCurrentPage((prevPage) => prevPage + 1); // Sayfayı artır

    } catch (error) {
      console.error("Error filtering customers:", error);
    }finally{
      setIsLoading(false);
    }

  };


  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchFilteredCustomers();
  };

  const handleRemoveFromCategory = async () => {
    // Seçili müşteri olup olmadığını kontrol ediyoruz
    const selectedCustomerIds = Object.keys(selectedCustomers).filter(
      (id) => selectedCustomers[id]
    );

    if (selectedCustomerIds.length === 0) {
      // Eğer hiç müşteri seçilmemişse uyarı mesajı göster
      window.alert("You have not selected a customer yet.");
      return;
    }

    // Eğer seçili müşteri varsa kullanıcıdan onay iste
    const confirm = window.confirm(
      "Are you sure you want to delete these customers from the category?"
    );
    if (!confirm) {
      return; // Kullanıcı işlemi onaylamazsa hiçbir şey yapma
    }

    // İşlemi gerçekleştirmek için gerekli API çağrılarını burada yapabilirsiniz
    try {
      const selectedCustomersWithCategories = Object.entries(selectedCustomers)
        .filter(([customerId, isSelected]) => isSelected)
        .map(([customerId]) => {
          const customer = customers.find((c) => c.id === parseInt(customerId));
          return { id: customer.id, categoryId: customer.categoryId };
        });

      // Seçili müşterileri kategorilere göre grupluyoruz
      const groupedByCategory = selectedCustomersWithCategories.reduce(
        (acc, customer) => {
          const { categoryId } = customer;
          if (!acc[categoryId]) acc[categoryId] = [];
          acc[categoryId].push(customer.id);
          return acc;
        },
        {}
      );

      // Her bir kategori için deleteFromCategory API çağrısı yapıyoruz
      for (const [categoryId, customerIds] of Object.entries(
        groupedByCategory
      )) {
        const requestPayload = {
          CategoryId: parseInt(categoryId),
          CustomerIds: customerIds.map((id) => parseInt(id)),
        };

        console.log(requestPayload);

        await deleteCustomersFromCategory(requestPayload);
      }

      setSelectedCustomers(() => ({}));

      fetchFilteredCustomers(); // İşlem sonrası müşterileri tekrar yükle
    } catch (error) {
      console.error("Error removing customers from category:", error);
    }
  };

  const handleRemoveFromSelectedGroup = async () => {
    setShowToast(false); // Toast'ı kapat ve sıfırla
    setToastMessage("");
    if (!filters.groupId) {
      window.alert("Lütfen bir grup seçin.");
      return;
    }

    // Seçili müşteri ID'lerini al
    const customerIds = Object.entries(selectedCustomers)
      .filter(([customerId, isSelected]) => isSelected)
      .map(([customerId]) => parseInt(customerId));

    if (customerIds.length === 0) {
      window.alert("Henüz müşteri seçmediniz.");
      return;
    }

    const confirm = window.confirm(
      "Bu müşterileri gruptan silmek istediğinize emin misiniz?"
    );
    if (!confirm) {
      return; // Kullanıcı işlemi onaylamazsa hiçbir şey yapma
    }

    const requestPayload = {
      GroupId: filters.groupId,
      CustomerIds: customerIds,
    };

    try {
      var response = await deleteCustomersFromGroup(requestPayload); // Backend'e istek gönder

      setToastMessage(`API response: ${response.data.message || "Success"}`);
      setShowToast(true);

      setSelectedCustomers(() => ({}));

      fetchFilteredCustomers();

      alert("Seçili müşteriler seçili gruptan başarıyla silindi.");
    } catch (error) {
      console.error(
        `Error removing customers from group ${filters.groupId}:`,
        error
      );
      setToastMessage(`API Error: ${error.message}`);
      setShowToast(true);
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    setShowToast(false); // Toast'ı kapat ve sıfırla
    setToastMessage("");
    if (!selectedFile) {
      window.alert("Lütfen bir dosya seçin.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      var response = await uploadExcelFile(formData); // Backend'e dosya yükleme isteği gönder
      setToastMessage(`API response: ${response.data.message || "Success"}`);
      setShowToast(true);
      setShowExcelModal(false);
      fetchFilteredCustomers(); // Yeni müşteri listesini getirmek için
    } catch (error) {
      setToastMessage(`API Error: ${error.message}`);
      setShowToast(true);
      console.error("Error uploading Excel file:", error);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedCustomers((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Seçili durumu tersine çevir
    }));
  };

  const handleAddToCategory = async () => {
    setShowToast(false); // Toast'ı kapat ve sıfırla
    setToastMessage("");
    const customerIds = Object.keys(selectedCustomers).filter(
      (id) => selectedCustomers[id]
    );

    if (selectedCategory) {
      var response = await addCustomersToCategory({
        customerIds,
        categoryId: selectedCategory,
      });
      setToastMessage(`API response: ${response.data.message || "Success"}`);
      setShowToast(true);
    }

    setShowCategoryModal(false);
    setSelectedCustomers(() => ({}));
    fetchFilteredCustomers(); // Seçim sonrası müşteri listesini günceller
  };

  const handleAddToGroup = async () => {
    setShowToast(false); // Toast'ı kapat ve sıfırla
    setToastMessage("");
    const customerIds = Object.keys(selectedCustomers).map((key) => {
      const value = selectedCustomers[key];
      return typeof value === "object" ? value.id : key; // Obje ise id'sini alıyoruz, yoksa key (ID) zaten
    });

    if (selectedGroup) {
      try {
        var response = await addCustomersToGroup({
          customerIds,
          groupId: selectedGroup,
        });
        console.log(response);
        setToastMessage(`API response: ${response.data.message || "Success"}`);
        setShowToast(true);
      } catch (error) {
        setToastMessage(`API Error: ${error.message}`);
        setShowToast(true);
      }
    }

    setShowGroupModal(false);
    setSelectedCustomers(() => ({}));
    setSelectedGroup("");
    fetchFilteredCustomers(); // Seçim sonrası müşteri listesini günceller
  };

  const handleBulkAddToGroupWithEmail = async () => {
    setShowToast(false); // Toast'ı kapat ve sıfırla
    setToastMessage("");
    if (selectedGroup) {
      setShowToast(true);
      try {
        var response = await bulkAddCustomersToGroupWithEmail({
          emails: bulkEmails,
          groupId: selectedGroup,
        });
        setToastMessage(`API response: ${response.data.message || "Success"}`);
        setShowToast(true);
      } catch (error) {
        setToastMessage(`API Error: ${error.message}`);
        setShowToast(true);
      }
    }

    setBulkAddWithEmailModal(false);
    setBulkEmails("");
    setSelectedGroup("");

    fetchFilteredCustomers(); // Seçim sonrası müşteri listesini günceller
  };

  const handleBulkAddToGroupWithUsername = async () => {
    setShowToast(false); // Toast'ı kapat ve sıfırla
    setToastMessage("");
    if (selectedGroup) {
      setShowToast(true);
      try {
        var response = await bulkAddCustomersToGroupWithUsername({
          usernames: bulkUsernames,
          groupId: selectedGroup,
        });
        setToastMessage(`API response: ${response.data.message || "Success"}`);
        setShowToast(true);
      } catch (error) {
        setToastMessage(`API Error: ${error.message}`);
        setShowToast(true);
      }
    }

    setBulkAddWithUsernameModal(false);
    setBulkUsernames("");
    setSelectedGroup("");

    fetchFilteredCustomers(); // Seçim sonrası müşteri listesini günceller
  };

  const handleSelectAllPage = () => {
    const newPageSelection = !allPageSelected;
    setAllPageSelected(newPageSelection);

    const updatedSelections = customers.reduce((acc, customer) => {
      acc[customer.id] = newPageSelection;
      return acc;
    }, {});

    setSelectedCustomers((prevState) => ({
      ...prevState,
      ...updatedSelections,
    }));
  };

  const handleSelectAll = async () => {
    try {
      if (!allSelected) {
        const filterPayload = {
          searchTerm: filters.searchTerm || null,
          categoryId: filters.categoryId ? parseInt(filters.categoryId) : null,
          groupId: filters.groupId ? parseInt(filters.groupId) : null,
          deposit: filters.deposit === "" ? null : filters.deposit === "true",
          totalDepositMin: filters.totalDepositMin
            ? parseFloat(filters.totalDepositMin)
            : null,
          totalDepositMax: filters.totalDepositMax
            ? parseFloat(filters.totalDepositMax)
            : null,
          customerReference: filters.customerReference
            ? parseInt(filters.customerReference)
            : null,
          countryCode: filters.countryCode || null,
          telephoneApproval:
            filters.telephoneApproval === ""
              ? null
              : filters.telephoneApproval === "true",
          registrationDateFrom: filters.registrationDateFrom
            ? new Date(filters.registrationDateFrom).toISOString()
            : null,
          registrationDateTo: filters.registrationDateTo
            ? new Date(filters.registrationDateTo).toISOString()
            : null,
          lastLoginDateFrom: filters.lastLoginDateFrom
            ? new Date(filters.lastLoginDateFrom).toISOString()
            : null,
          lastLoginDateTo: filters.lastLoginDateTo
            ? new Date(filters.lastLoginDateTo).toISOString()
            : null,
        };
        // Backend'den tüm müşteri ID'lerini çek
        const response = await getFilteredCustomerIds(filterPayload);
        const allCustomerIds = response.data.object; // API'den gelen tüm ID'ler

        // Tüm müşterileri seçilmiş hale getir
        setSelectedCustomers(allCustomerIds);
      } else {
        // Seçili olan tüm müşterileri kaldır
        setSelectedCustomers([]);
      }

      // "Select All" durumunu tersine çevir
      setAllSelected(!allSelected);
    } catch (error) {
      console.error("Error fetching all customer IDs:", error);
    }
  };

  const indexOfLastCustomer = currentPage * customersPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage;
  const currentCustomers = customers.slice(
    indexOfFirstCustomer,
    indexOfLastCustomer
  );

  const totalPages = Math.ceil(customers.length / customersPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      if (
        i <= 5 ||
        i > totalPages - 5 ||
        (i >= currentPage - 2 && i <= currentPage + 2)
      ) {
        pageNumbers.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </Pagination.Item>
        );
      } else if (i === 6 || i === totalPages - 5) {
        pageNumbers.push(<Pagination.Ellipsis key={i} />);
      }
    }
    return pageNumbers;
  };

  return (
    <Container className="mt-5" style={{ maxWidth: "100%" }}>
      <h2 className="text-center mb-4" style={{ marginRight: "200px" }}>
        Customer Management
      </h2>
      {showToast && (
        <Toast
          message={toastMessage}
          onClose={() => setShowToast(false)} // Toast'ı kapat
          duration={4000} // Toast'ın süresini ayarlayabilirsin
        />
      )}

      <Form onSubmit={handleFilterSubmit}>
        <Row>
          <Col>
            <Form.Group controlId="formSearchTerm">
              <Form.Label>Search Term</Form.Label>
              <Form.Control
                type="text"
                name="searchTerm"
                value={filters.searchTerm}
                onChange={handleFilterChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formCategoryId">
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                name="categoryId"
                value={filters.categoryId}
                onChange={handleFilterChange}
              >
                <option value="">Select Category</option>
                {Array.isArray(categories) &&
                  categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formGroupId">
              <Form.Label>Group</Form.Label>
              <Form.Control
                as="select"
                name="groupId"
                value={filters.groupId}
                onChange={handleFilterChange}
              >
                <option value="">Select Group</option>
                {Array.isArray(groups) &&
                  groups.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group controlId="formTotalDepositMin">
              <Form.Label>Total Deposit Min</Form.Label>
              <Form.Control
                type="number"
                name="totalDepositMin"
                value={filters.totalDepositMin}
                onChange={handleFilterChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formTotalDepositMax">
              <Form.Label>Total Deposit Max</Form.Label>
              <Form.Control
                type="number"
                name="totalDepositMax"
                value={filters.totalDepositMax}
                onChange={handleFilterChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formDeposit">
              <Form.Label>Deposit</Form.Label>
              <Form.Control
                as="select"
                name="deposit"
                value={filters.deposit}
                onChange={handleFilterChange}
              >
                <option value="">Select Deposit Status</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group controlId="formCustomerReference">
              <Form.Label>Customer Reference</Form.Label>
              <Form.Control
                as="select"
                name="customerReference"
                value={filters.customerReference}
                onChange={handleFilterChange}
              >
                <option value="">Select Reference</option>
                {Array.isArray(references) &&
                  references.map((reference) => (
                    <option key={reference.value} value={reference.value}>
                      {reference.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formCountryCode">
              <Form.Label>Country Code</Form.Label>
              <Form.Control
                type="text"
                name="countryCode"
                value={filters.countryCode}
                onChange={handleFilterChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group controlId="formTelephoneApproval">
              <Form.Label>Telephone Approval</Form.Label>
              <Form.Control
                as="select"
                name="telephoneApproval"
                value={filters.telephoneApproval}
                onChange={handleFilterChange}
              >
                <option value="">Select Approval Status</option>
                <option value="true">Approved</option>
                <option value="false">Not Approved</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group controlId="formRegistrationDateFrom">
              <Form.Label>Registration Date From</Form.Label>
              <Form.Control
                type="date"
                name="registrationDateFrom"
                value={filters.registrationDateFrom}
                onChange={handleFilterChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formRegistrationDateTo">
              <Form.Label>Registration Date To</Form.Label>
              <Form.Control
                type="date"
                name="registrationDateTo"
                value={filters.registrationDateTo}
                onChange={handleFilterChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group controlId="formLastLoginDateFrom">
              <Form.Label>Last Login Date From</Form.Label>
              <Form.Control
                type="date"
                name="lastLoginDateFrom"
                value={filters.lastLoginDateFrom}
                onChange={handleFilterChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formLastLoginDateTo">
              <Form.Label>Last Login Date To</Form.Label>
              <Form.Control
                type="date"
                name="lastLoginDateTo"
                value={filters.lastLoginDateTo}
                onChange={handleFilterChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" type="submit" className="mt-3">
          Apply Filters
        </Button>
      </Form>

      <Row className="align-items-center justify-content-between mb-3 mt-3">
        <Col className="d-flex">
          <Button
            variant="primary"
            onClick={() => setShowExcelModal(true)}
            className="me-2"
          >
            Import Customers from Excel
          </Button>

          <Button
            variant="info"
            onClick={() => setShowCategoryModal(true)}
            className="me-2"
          >
            Add to Category
          </Button>

          <Button
            variant="danger"
            onClick={() => handleRemoveFromCategory()}
            className="me-2"
          >
            Remove from Category
          </Button>

          <Button
            variant="info"
            onClick={() => setShowGroupModal(true)}
            className="me-2"
          >
            Add to Group
          </Button>

          <Button
            variant="danger"
            className="me-2"
            onClick={() => handleRemoveFromSelectedGroup()}
          >
            Remove from Group
          </Button>

          <Button
            variant="primary"
            onClick={() => setBulkAddWithEmailModal(true)}
            className="me-2"
          >
            Bulk Add to Group With Email
          </Button>

          <Button
            variant="primary"
            onClick={() => setBulkAddWithUsernameModal(true)}
            className="me-2"
          >
            Bulk Add to Group With Username
          </Button>
        </Col>

        <Col className="d-flex justify-content-end">
          <span>Total Count: {customerCount}</span>
        </Col>
      </Row>

      <Form.Check
        type="checkbox"
        onChange={handleSelectAllPage}
        checked={allPageSelected}
        label="Select All Page"
      />
      <Form.Check
        type="checkbox"
        onChange={handleSelectAll}
        checked={allSelected}
        label="Select All"
        className="mt-2"
      />
      <br></br>
      <Table
        striped
        bordered
        hover
        className="modern-table"
        style={{fontSize: "14px",
          width: "100%", // Tablo genişliği yüzde 100
          tableLayout: "fixed", // Tabloyu tam genişlikte yapıyoruz
           }}
      >
        <thead>
          <tr>
            <th>Select</th>
            <th>User Name</th>
            <th>Name</th>
            <th>Last Name</th>
            <th>Country Code</th>
            <th>Telephone Number</th>
            <th>Email</th>
            <th>Total Deposit</th>
            <th>Registration Date</th>
            <th>Last Login Date</th>
            <th>Customer Reference</th>
            <th>Category Name</th>
            <th>Groups</th>
          </tr>
        </thead>
        <tbody>
          {customers.map((customer) => (
            <tr key={customer.id}>
              <td>
                <Form.Check
                  type="checkbox"
                  checked={!!selectedCustomers[customer.id]} // Checkbox durumunu kontrol ediyoruz
                  onChange={() => handleCheckboxChange(customer.id)} // Checkbox değişimini yakalıyoruz
                />
              </td>
              <td>{customer.userName}</td>
              <td>{customer.name}</td>
              <td>{customer.lastName}</td>
              <td>{customer.countryCode}</td>
              <td>{customer.telephoneNumber}</td>
              <td>{customer.email}</td>
              <td>{customer.totalDeposit}</td>
              <td>
                {new Date(customer.registrationDate).toLocaleDateString()}
              </td>
              <td>{new Date(customer.lastLoginDate).toLocaleDateString()}</td>
              <td>{customer.customerReference}</td>
              <td>{customer.categoryName}</td>
              <td>{customer.groups}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {isLoading && (
        <div className="text-center my-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
    )}
    {!hasMore && !isLoading && <p>No more customers.</p>} {/* Daha fazla müşteri yoksa mesaj */}

      <Modal show={showExcelModal} onHide={() => setShowExcelModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Import Customers from Excel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFile">
              <Form.Label>Upload Excel File</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowExcelModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpload}>
            Import
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCategoryModal}
        onHide={() => setShowCategoryModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formCategory">
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="">Select Category</option>
                {Array.isArray(categories) &&
                  categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowCategoryModal(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleAddToCategory}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showGroupModal}
        onHide={() => {
          setShowGroupModal(false);
          setSelectedGroup("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddToGroup();
            }}
          >
            <Form.Group controlId="formGroup">
              <Form.Label>Group</Form.Label>
              <Form.Control
                as="select"
                value={selectedGroup}
                onChange={(e) => setSelectedGroup(e.target.value)}
              >
                <option value="">Select Group</option>
                {Array.isArray(groups) &&
                  groups.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowGroupModal(false);
              setSelectedGroup("");
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleAddToGroup}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showBulkAddWithEmailModal}
        onHide={() => {
          setBulkAddWithEmailModal(false);
          setSelectedGroup("");
          setBulkEmails("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault(); // Sayfa yenilenmesini engelle
              handleBulkAddToGroupWithEmail(); // "Save Changes" fonksiyonunu tetikle
            }}
          >
            <Form.Group controlId="formGroup">
              <Form.Label>Group</Form.Label>
              <Form.Control
                as="select"
                value={selectedGroup}
                onChange={(e) => setSelectedGroup(e.target.value)}
              >
                <option value="">Select Group</option>
                {Array.isArray(groups) &&
                  groups.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="emailStringRegion">
              <Form.Label>Emails</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter emails"
                value={bulkEmails}
                onChange={(e) => setBulkEmails(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setBulkAddWithEmailModal(false);
              setBulkEmails("");
              setSelectedGroup("");
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleBulkAddToGroupWithEmail}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showBulkAddWithUsernameModal}
        onHide={() => {
          setBulkAddWithUsernameModal(false);
          setSelectedGroup("");
          setBulkUsernames("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault(); // Sayfa yenilenmesini engelle
              handleBulkAddToGroupWithUsername(); // "Save Changes" fonksiyonunu tetikle
            }}
          >
            <Form.Group controlId="formGroup">
              <Form.Label>Group</Form.Label>
              <Form.Control
                as="select"
                value={selectedGroup}
                onChange={(e) => setSelectedGroup(e.target.value)}
              >
                <option value="">Select Group</option>
                {Array.isArray(groups) &&
                  groups.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="usernameStringRegion">
              <Form.Label>Usernames</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter usernames"
                value={bulkUsernames}
                onChange={(e) => setBulkUsernames(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setBulkAddWithUsernameModal(false);
              setBulkUsernames("");
              setSelectedGroup("");
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleBulkAddToGroupWithUsername}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default CustomerPage;
