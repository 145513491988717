import React, { useState, useEffect } from "react";
import { register, getCountries } from "../../api";
import { useNavigate } from "react-router-dom";
import { Container, Form, Button, Alert, Spinner } from "react-bootstrap";

const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [estimatedSmsCount, setEstimatedSmsCount] = useState("");
  const [countries, setCountries] = useState([]); // Ülkeleri tutacak state
  const [selectedCountry, setSelectedCountry] = useState(""); // Seçilen ülkeyi tutacak state

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchCountries();
  }, []);

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await register({
        UserName: username,
        Email: email,
        Password: password,
        ConfirmPassword: confirmPassword,
      });
      setMessage(response.data.message);
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      setError("Registration failed");
    }
    setLoading(false);
  };

  const handleCountryChange = async (e) => {
    const selected = e.target.value;
    setSelectedCountry(selected);
  }

  const fetchCountries = async () => {
    try {
      const response = await getCountries(); // API'den ülkeleri çekiyoruz
      console.log(response);
      setCountries(response.data); // Ülke listesini güncelle
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  return (
    <Container
      className="d-flex flex-column justify-content-center align-items-center"
      style={{
        width: "100%",
        maxWidth: "500px",
        marginRight: "620px",
        marginTop: "100px",
      }}
    >
      <h2>Register</h2>
      <Form
        onSubmit={handleRegister}
        style={{ width: "100%", maxWidth: "400px" }}
      >
        <Form.Group className="mb-3" controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formSurname">
          <Form.Label>Surname</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Surname"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formCompanyName">
          <Form.Label>Company Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Company Name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formEstimatedSms">
          <Form.Label>Estimated Sms Count</Form.Label>
          <Form.Control
            as="select" // Dropdown olarak değiştiriyoruz
            value={estimatedSmsCount}
            onChange={(e) => setEstimatedSmsCount(e.target.value)}
          >
            <option value="">Select Estimated Sms Count</option>
            <option value="<1000">{"<1000"}</option>
            <option value="1000-5000">1000-5000</option>
            <option value="5000-10000">5000-10000</option>
            <option value="10000-20000">10000-20000</option>
            <option value="20000-50000">20000-50000</option>
            <option value="50000-100000">50000-100000</option>
            <option value="100000+">100000+</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="countrySelect">
        <Form.Label >
          Select Country
        </Form.Label>
        <Form.Control
          as="select"
          value={selectedCountry}
          onChange={handleCountryChange}
        >
          <option value="">Select a country</option>
          {countries.map((country) => (
            <option key={country.value} value={country.value}>
              {country.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <br></br>

        <Button
          variant="primary"
          type="submit"
          disabled={loading}
          className="me-2"
          style={{marginLeft:"150"}}
        >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Contact"
          )}
        </Button>
      </Form>
      {message && (
        <Alert variant="success" className="mt-3">
          {message}
        </Alert>
      )}
      {error && (
        <Alert variant="danger" className="mt-3">
          {error}
        </Alert>
      )}
    </Container>
  );
};

/*
<Form.Group className="mb-3" controlId="formUsername">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Enter password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formConfirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Confirm password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </Form.Group>

                 <Button
          variant="primary"
          type="submit"
          disabled={loading}
          className="me-2"
        >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Register"
          )}
        </Button>
*/


export default Register;