import React from "react";
import "./About.css"; // Stil dosyası buraya gelecek

const About = () => {
  return (
    <div className="container my-5">
      {/* Hero Section */}
      <section className="text-center mb-5">
        <h1 className="display-4 font-weight-bold">About Text4Reach</h1>
        <p className="lead">Empowering businesses with efficient communication solutions since 2021.</p>
      </section>

      {/* Company Overview */}
      <section className="my-5">
        <h2 className="font-weight-bold">Our Story</h2>
        <p>
          Text4Reach was founded in 2021 with a simple mission: to help businesses connect with their audiences
          in the most efficient way possible. In an increasingly digital world, we realized that businesses needed a
          reliable, scalable platform to communicate via SMS and email with their customers, no matter where they are located.
        </p>
      </section>

      {/* What We Offer */}
      <section className="my-5">
        <h2 className="font-weight-bold">What We Offer</h2>
        <p>
          At Text4Reach, we specialize in providing cutting-edge communication solutions to businesses of all sizes.
          Whether you're a startup looking to send your first email campaign or a global enterprise managing large-scale SMS
          messaging, we have the tools and expertise to help you succeed. Our platform enables businesses to send SMS
          messages to over 190 countries and manage email campaigns through seamless SMTP and Mailjet integrations.
        </p>
      </section>

      {/* Our Vision */}
      <section className="my-5">
        <h2 className="font-weight-bold">Our Vision</h2>
        <p>
          Our vision is to create a world where businesses can effortlessly communicate with their customers, building
          stronger relationships through personalized and targeted messaging. We believe that the future of business
          communication lies in simplicity, scalability, and reliability, and we strive to provide that with every feature
          we develop.
        </p>
      </section>

      {/* Call to Action */}
      <section className="text-center my-5">
        <h3>Ready to join us on this journey?</h3>
        <a href="/register" className="btn btn-primary btn-lg px-5">
          Get Started with Text4Reach
        </a>
      </section>
    </div>
  );
};

export default About;
