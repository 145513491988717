import React from "react";
import "./Solutions.css"; // Stil dosyası buraya gelecek

const Solutions = () => {
  return (
    <div className="container my-5">
      {/* Hero Section */}
      <section className="text-center mb-5">
        <h1 className="display-4 font-weight-bold">Our Solutions</h1>
        <p className="lead">
          Deliver messages efficiently to your customers worldwide.
        </p>
      </section>

      {/* Global SMS Service */}
      <section className="my-5">
        <h2 className="font-weight-bold">Send SMS to Anywhere in the World</h2>
        <p>
          Our platform allows you to send SMS to 190+ countries. Whether your
          customers are in Europe, Asia, or the Americas, our robust SMS
          delivery infrastructure ensures that your messages are delivered
          promptly and reliably, no matter the destination.
        </p>
      </section>

      {/* SMTP and Mailjet Integration */}
      <section className="my-5">
        <h2 className="font-weight-bold">SMTP and Mailjet Integration</h2>
        <p>
          We have integrated with leading email service providers, including
          Mailjet, for your email marketing campaigns. Simply provide your
          Mailjet API key, and you can start sending emails directly from our
          platform. No need to manage complex SMTP servers — Mailjet handles
          the heavy lifting, ensuring your emails are delivered efficiently.
        </p>
      </section>

      {/* Customer Data Management and Group Targeting */}
      <section className="my-5">
        <h2 className="font-weight-bold">Manage Your Customer Data and Target Groups</h2>
        <p>
          Upload your customer data easily into our platform and organize them
          into groups based on your marketing needs. Whether you're running a
          targeted SMS or email campaign, simply select the groups you want to
          reach and hit send. Our system allows you to manage large volumes of
          customer information efficiently, making it easier than ever to
          target specific segments of your audience with personalized messages.
        </p>
      </section>

      {/* Call to Action */}
      <section className="text-center my-5">
        <h3>Ready to improve your communication?</h3>
        <a href="/register" className="btn btn-primary btn-lg px-5">
          Get Started Today
        </a>
      </section>
    </div>
  );
};

export default Solutions;
