import React, { useState, useEffect } from "react";
import { Container, Button, Table, Modal, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Toast from "../otherComponents/Toast";
import "../customerComponents/CustomerPage.css"

import {
  getTransactions,
  createDeposit,
  getUserBalance,
  cancelTransaction,
} from "../../api"; // Backend'den transactionları getiren ve deposit oluşturan API çağrıları

const DepositPage = () => {
  const [transactions, setTransactions] = useState([]);
  const [pendingTransactionExists, setPendingTransactionExists] =
    useState(false); // Pending transaction kontrolü
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [userBalance, setUserBalance] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [hideFailedTransactions, setHideFailedTransactions] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn !== null) {
      isLoggedInFunc();
      fetchTransactions();
      fetchUserBalance();
    }
  }, [isLoggedIn]);

  const isLoggedInFunc = () => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  };

  const fetchTransactions = async () => {
    try {
      const response = await getTransactions(); // Transaction verilerini backend'den çekiyoruz
      const data = response.data.object;
      setTransactions(data);

      // Pending durumda bir transaction varsa kontrol et
      const hasPendingTransaction = data.some(
        (transaction) => transaction.status === "Pending"
      );
      setPendingTransactionExists(hasPendingTransaction);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  const fetchUserBalance = async () => {
    try {
      const response = await getUserBalance(); // Transaction verilerini backend'den çekiyoruz
      setUserBalance(response.data.object.balance);
    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  };

  const handleCancelTransaction = async (transactionId) => {
    setShowToast(false); // Toast'ı kapat ve sıfırla
    setToastMessage("");
    try {
      var response = await cancelTransaction(transactionId);
      setToastMessage(`API response: ${response.data.message || "Success"}`);
      setShowToast(true);
      fetchUserBalance();
      fetchTransactions();
    } catch (error) {
      setToastMessage(`API Error: ${error.message}`);
      setShowToast(true);
    }
  };

  const handleCreateDeposit = async () => {
    setShowToast(false); // Toast'ı kapat ve sıfırla
    setToastMessage("");
    if (!pendingTransactionExists) {
      try {
        setShowCreateModal(false);
        var response = await createDeposit(); // Yeni deposit talebi oluşturuyoruz
        setToastMessage(`API response: ${response.data.message || "Success"}`);
        setShowToast(true);
        fetchUserBalance();
        fetchTransactions(); // Transaction listesini güncelle
      } catch (error) {
        console.error("Error creating deposit request:", error);
      }
    } else {
      setToastMessage(`You have already pending transaction.`);
      setShowToast(true);
    }
  };

  return (
    <Container className="mt-5">
      <div
        className="d-flex justify-content-center align-items-center mb-4"
        style={{ marginTop: "-60px" }}
      >
        <h3 style={{ fontWeight: "bold", marginRight: "1090px" }}>
          Balance : {Number(userBalance).toFixed(2)} USD
        </h3>
      </div>

      {showToast && (
        <Toast
          message={toastMessage}
          onClose={() => setShowToast(false)} // Toast'ı kapat
          duration={2000} // Toast'ın süresini ayarlayabilirsin
        />
      )}

      <Form.Check
        type="checkbox"
        label="Hide Failed Transactions"
        checked={hideFailedTransactions}
        onChange={(e) => setHideFailedTransactions(e.target.checked)}
      />
      <br></br>

      <div style={{ overflowX: "auto", marginRight:'10px' }}>
        {" "}
        <Table
          striped
          bordered
          hover
          responsive="sm"
          className="modern-table"
          style={{ width: "100%", margin: "0 auto" }}
        >
          <thead>
            <tr>
              <th style={{ width: "100px" }}>Amount (USDT)</th>
              <th style={{ width: "100px" }}>Status</th>
              <th style={{ width: "100px" }}>Confirmed Date</th>
              <th style={{ width: "100px" }}>Created Date</th>
              <th style={{ width: "200px" }}>From Wallet Address</th>
              <th style={{ width: "200px" }}>Wallet Address</th>
              <th style={{ width: "150px" }}>Wallet Network Type</th>
              <th style={{ width: "120px" }}>Wallet Type</th>
              <th style={{ width: "120px" }}></th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(transactions) &&
              transactions
                .filter(
                  (transaction) =>
                    !hideFailedTransactions || transaction.status !== "Failed"
                )
                .map((transaction) => (
                  <tr key={transaction.transactionId}>
                    <td>{Number(transaction.amount).toFixed(2)}</td>
                    <td>{transaction.status}</td>
                    <td>{String(transaction.confirmedAt).substring(0, 19)}</td>
                    <td>{String(transaction.createdAt).substring(0, 19)}</td>
                    <td>{transaction.foreignWalletAddress}</td>
                    <td>{transaction.address}</td>
                    <td>{transaction.walletNetworkType}</td>
                    <td>{transaction.walletType}</td>
                    <td>
                      {transaction.status === "Pending" && (
                        <Button
                          variant="danger"
                          onClick={() =>
                            handleCancelTransaction(transaction.transactionId)
                          }
                        >
                          Cancel
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </div>

      <br />

      {/* Create Deposit Button */}
      {!pendingTransactionExists && (
        <Button
          variant="primary"
          onClick={() => setShowCreateModal(true)}
          className="mb-3"
        >
          Create New Deposit Request
        </Button>
      )}

      {pendingTransactionExists && (
        <>
          <h3 className="text-danger">
            You have a pending transaction. You can deposit your funds with that
            deposit request.
          </h3>
          <br />
          <h3 className="text-danger">
            Your pending deposit will fail within 2 hours, your deposit will not
            be credited to your account without creating a new deposit request.
          </h3>
        </>
      )}

      {/* Create Deposit Modal */}
      <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Deposit Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to create a new deposit request?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCreateModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCreateDeposit}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default DepositPage;
