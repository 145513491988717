import React, { useState, useEffect } from "react";
import { Container, Button, Modal, Form, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Toast from "../otherComponents/Toast";
import { getUser, updateUser, getSmsRemainings } from "../../api";
import "../../App.css";

const AccountSettings = () => {
  const [accountSettings, setAccountSettings] = useState({
    email: "",
    smtpServer: "",
    smtpPort: "",
    smtpUser: "",
    smtpPass: "",
    mailjetApiKey: "",
    mailjetSecretKey: "",
    useMailjet: false,
  });
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [smsRemainings, setSmsRemainings] = useState([]); // SMS kalan miktarını tutacak state
  const [showEditModal, setShowEditModal] = useState(false);
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn !== null) {
      isLoggedInFunc();
      fetchAccount();
      fetchSmsRemainings();
    }
  }, [isLoggedIn]);

  const isLoggedInFunc = () => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  };

  const fetchSmsRemainings = async () => {
    try {
      const response = await getSmsRemainings(); // SMS kalanları çek
      console.log(response);
      setSmsRemainings(response.data.object); // Gelen veriyi state'e kaydet
    } catch (error) {
      console.error("Error fetching SMS remainings:", error);
    }
  };

  const fetchAccount = async () => {
    try {
      const response = await getUser();
      const data = response.data.object;
      console.log(response);
      setAccountSettings({
        email: data.email,
        smtpServer: data.smtpServer,
        smtpPort: data.smtpPort,
        smtpUser: data.smtpUser,
        smtpPass: data.smtpPass,
        mailjetApiKey: data.mailjetApiKey,
        mailjetSecretKey: data.mailjetSecretKey,
        useMailjet: data.useMailjet,
      });
    } catch (error) {
      console.error("Error fetching account:", error);
    }
  };

  // Formdaki herhangi bir alan değiştiğinde, accountSettings state'i güncellenir
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setAccountSettings((prevSettings) => ({
      ...prevSettings,
      [name]: type === "checkbox" ? checked : value, // Checkboxlar için checked, diğerleri için value
    }));
  };

  // Form submit edildiğinde backend'e accountSettings gönderilir
  const handleUpdateUser = async () => {
    setShowToast(false); // Toast'ı kapat ve sıfırla
    setToastMessage("");
    try {
      var response = await updateUser({
        email: accountSettings.email,
        smtpServer: accountSettings.smtpServer,
        smtpPort: accountSettings.smtpPort,
        smtpUser: accountSettings.smtpUser,
        smtpPass: accountSettings.smtpPass,
        mailjetApiKey: accountSettings.mailjetApiKey,
        mailjetSecretKey: accountSettings.mailjetSecretKey,
        useMailjet: accountSettings.useMailjet,
      });
      setToastMessage(`API response: ${response.data.message || "Success"}`);
      setShowToast(true);
      setShowEditModal(false);
      fetchAccount();
    } catch (error) {
      console.error("Error updating account:", error);
      setToastMessage(`API Error: ${error.message}`);
      setShowToast(true);
    }
  };

  return (
    <Container className="mt-5">
      <h2
        className="text-center mb-4"
        style={{ marginRight: "200px", marginTop: "-60px" }}
      >
        Account Settings
      </h2>
      {showToast && (
        <Toast
          message={toastMessage}
          onClose={() => setShowToast(false)} // Toast'ı kapat
          duration={2500} // Toast'ın süresini ayarlayabilirsin
        />
      )}

      <div style={{ marginRight: "130px" }}>
        {accountSettings && (
          <Table
            responsive
            striped
            bordered
            hover
            className="modern-table"
            style={{ width: "100%", maxWidth: "2500px", margin: "0 auto" }}
          >
            <thead>
                <th style={{ width: "10%" }}> </th>
                <th style={{ width: "20%" }}> </th>

            </thead>
            <tbody>
              <tr>
                <td style={{ fontWeight: "bold" }}>Email</td>
                <td>{accountSettings.email}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Sender ID</td>
                <td>{accountSettings.senderId ? accountSettings.senderId  : "Contact Us for register Sender Id."}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>SMTP Server</td>
                <td>{accountSettings.smtpServer}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>SMTP Port</td>
                <td>{accountSettings.smtpPort}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>SMTP User</td>
                <td>{accountSettings.smtpUser}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>SMTP Password</td>
                <td>{accountSettings.smtpPass}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Mailjet API Key</td>
                <td>{accountSettings.mailjetApiKey}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Mailjet Secret Key</td>
                <td>{accountSettings.mailjetSecretKey}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>Use Mailjet</td>
                <td>{accountSettings.useMailjet ? "Yes" : "No"}</td>
              </tr>
            </tbody>
          </Table>
        )}
      </div>

      <br />

      <br />
      <div style={{ marginRight: "130px" }}>
        <h4 className="text-center mt-5" style={{ marginRight: "65px" }}>SMS Remaining by Country</h4>
        {smsRemainings.length > 0 ? (
          <Table
            responsive
            striped
            bordered
            hover
            className="modern-table"
            style={{ width: "100%", maxWidth: "2500px", margin: "0 auto" }}
          >
            <thead>
                <th style={{ width: "10%" }}> </th>
                <th style={{ width: "20%" }}> </th>
              <tr>
                <th>Country</th>
                <th>Remaining SMS</th>
              </tr>
            </thead>
            <tbody>
              {smsRemainings.map((remaining, index) => (
                <tr key={index}>
                  <td>{remaining.country}</td>
                  <td>{remaining.remaining}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p className="text-center" style={{ marginRight: "65px" }}>No remaining SMS data available</p>
        )}
      </div>

      <br />

      <Button
        variant="primary"
        onClick={() => setShowEditModal(true)}
        className="mt-3"
      >
        Edit Account Settings
      </Button>

      {/* Edit Modal */}
      <Modal
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false);
          fetchAccount();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Account Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={accountSettings.email}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formSmtpServer">
              <Form.Label>SMTP Server</Form.Label>
              <Form.Control
                type="text"
                name="smtpServer"
                value={accountSettings.smtpServer}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formSmtpPort">
              <Form.Label>SMTP Port</Form.Label>
              <Form.Control
                type="text"
                name="smtpPort"
                value={accountSettings.smtpPort}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formSmtpUser">
              <Form.Label>SMTP User</Form.Label>
              <Form.Control
                type="text"
                name="smtpUser"
                value={accountSettings.smtpUser}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formSmtpPass">
              <Form.Label>SMTP Password</Form.Label>
              <Form.Control
                type="password"
                name="smtpPass"
                value={accountSettings.smtpPass}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formMailjetApiKey">
              <Form.Label>Mailjet API Key</Form.Label>
              <Form.Control
                type="text"
                name="mailjetApiKey"
                value={accountSettings.mailjetApiKey}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formMailjetSecretKey">
              <Form.Label>Mailjet Secret Key</Form.Label>
              <Form.Control
                type="text"
                name="mailjetSecretKey"
                value={accountSettings.mailjetSecretKey}
                onChange={handleChange}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="formUseMailjet">
              <Form.Check
                type="checkbox"
                label="Use Mailjet"
                name="useMailjet"
                checked={accountSettings.useMailjet}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowEditModal(false);
              fetchAccount();
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateUser}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default AccountSettings;
