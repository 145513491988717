import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../otherComponents/Toast";
import { createGroup, updateGroup, deleteGroup, getGroups } from "../../api";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Table,
  Modal,
} from "react-bootstrap";
import "./CustomerPage.css";

const GroupPage = () => {
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [newGroupName, setNewGroupName] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn !== null) {
      isLoggedInFunc();
      fetchGroups();
    }
  }, [isLoggedIn]);

  const isLoggedInFunc = () => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await getGroups();
      console.log(response.data.object);
      setGroups(response.data.object);
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  const handleCreateGroup = async () => {
    setShowToast(false); // Toast'ı kapat ve sıfırla
    setToastMessage("");
    try {
      var response = await createGroup({ name: newGroupName });
      setToastMessage(`API response: ${response.data.message || "Success"}`);
      setShowToast(true);
      setNewGroupName("");
      setShowCreateModal(false);
      fetchGroups();
    } catch (error) {
      console.error("Error creating group:", error);
      setToastMessage(`API Error: ${error.message}`);
      setShowToast(true);
    }
  };

  const handleUpdateGroup = async (id) => {
    setShowToast(false); // Toast'ı kapat ve sıfırla
    setToastMessage("");
    try {
      var response = await updateGroup({ id: id, name: selectedGroup.name });
      setToastMessage(`API response: ${response.data.message || "Success"}`);
      setShowToast(true);
      setSelectedGroup(null);
      setShowEditModal(false);
      fetchGroups();
    } catch (error) {
      console.error("Error updating group:", error);
      setToastMessage(`API Error: ${error.message}`);
      setShowToast(true);
    }
  };

  const handleDeleteGroup = async (id) => {
    setShowToast(false); // Toast'ı kapat ve sıfırla
    setToastMessage("");
    const confirm = window.confirm(
      "Bu grubu silmek istediğinize emin misiniz?"
    );
    if (!confirm) {
      return; // Kullanıcı işlemi onaylamazsa hiçbir şey yapma
    }
    try {
      var response = await deleteGroup(id);
      setToastMessage(`API response: ${response.data.message || "Success"}`);
      setShowToast(true);
      fetchGroups();
    } catch (error) {
      console.error("Error deleting group:", error);
      setToastMessage(`API Error: ${error.message}`);
      setShowToast(true);
    }
  };

  return (
    <Container className="mt-5">
      <h2 className="text-center mb-4" style={{ marginRight: "290px" }}>
        Group Management
      </h2>

      {showToast && (
        <Toast
          message={toastMessage}
          onClose={() => setShowToast(false)} // Toast'ı kapat
          duration={2000} // Toast'ın süresini ayarlayabilirsin
        />
      )}

      <Button
        variant="primary"
        onClick={() => setShowCreateModal(true)}
        className="mb-3"
      >
        Create Group
      </Button>
      <div style={{ marginRight: "130px" }}>
        <Table striped bordered hover className="modern-table">
          <thead>
            <tr>
            <th style={{ width: "5%" }}> Name</th>
            <th style={{ width: "25%" }}> Actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(groups) &&
              groups.map((group) => (
                <tr key={group.id}>
                  <td>{group.name}</td>
                  <td>
                    <Button
                      variant="warning"
                      className="me-2"
                      onClick={() => {
                        setSelectedGroup(group);
                        setShowEditModal(true);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteGroup(group.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>

      <Modal
        show={showCreateModal}
        onHide={() => {
          setShowCreateModal(false);
          setNewGroupName("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formGroupNameCreate">
              <Form.Label>Group Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter group name"
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowCreateModal(false);
              setNewGroupName("");
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateGroup}>
            Create Group
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formGroupNameEdit">
              <Form.Label>Group Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter group name"
                value={selectedGroup ? selectedGroup.name : ""}
                onChange={(e) =>
                  setSelectedGroup({
                    ...selectedGroup,
                    name: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => handleUpdateGroup(selectedGroup.id)}
          >
            Update Group
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default GroupPage;
