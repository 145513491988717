import React from "react";
import "./Help.css"; // Stil dosyası buraya gelecek

const Help = () => {
  return (
    <div className="container my-5">
      {/* Hero Section */}
      <section className="text-center mb-5">
        <h1 className="display-4 font-weight-bold">How Can We Help You?</h1>
        <p className="lead">We're here to assist you with any questions or issues you might have.</p>
      </section>

      {/* FAQ Section */}
      <section className="my-5">
        <h2 className="font-weight-bold">Frequently Asked Questions</h2>
        <div className="faq">
          <h4>1. How can I send SMS through Text4Reach?</h4>
          <p>To send SMS, you need to sign up for an account, add your contact lists, and then use our web-based tool or API to send messages globally.</p>

          <h4>2. What is the cost of sending SMS?</h4>
          <p>Pricing for sending SMS varies depending on the destination country and the volume of messages. You can check our pricing page for more detailed information.</p>

          <h4>3. How do I integrate Mailjet with Text4Reach?</h4>
          <p>Simply enter your Mailjet API key in the integrations section, and you'll be able to send emails directly through Mailjet using Text4Reach's interface.</p>

          <h4>4. How can I upload customer data?</h4>
          <p>You can upload customer data in XLSX format through our platform. Once uploaded, you can organize your contacts into groups for targeted messaging.</p>
        </div>
      </section>

      {/* Contact Section */}
      <section className="my-5 text-center">
        <h2 className="font-weight-bold">Need Further Assistance?</h2>
        <p>
          If you need further help or have any specific questions, feel free to reach out to us. You can contact us directly at <strong>info@text4reach.com</strong>.
        </p>
        <p>We're here to assist you with any questions you might have about using our platform.</p>
        <a href="mailto:info@text4reach.com" className="btn btn-primary btn-lg px-5">
          Contact Us
        </a>
      </section>
    </div>
  );
};

export default Help;
