import React, { useState, useEffect } from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import logo from "../../assets/logo.png";

const NavigationBar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [isLoggedIn]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    window.location.reload();
  };

  return (
    <Navbar bg="light" expand="lg" className="shadow-sm">
      <div className="container d-flex justify-content-between align-items-center">
        {/* Logo */}
        {!isLoggedIn ? (
          <Navbar.Brand href="/">
            <img
              src={logo} // Logonun yolu
              alt="Logo"
              style={{
                maxWidth: "200px",
                marginTop: "30px",
                marginLeft: "-120px",
                borderRadius: "22px", // Giriş yapılmadığında köşeler daha yuvarlak
                boxShadow: "10px 30px 38px rgba(0, 0, 0, 0.1)", // Hafif bir gölge
              }}
            />
          </Navbar.Brand>
        ) : (
          <Navbar.Brand href="/customers">
            <img
              src={logo} // Logonun yolu
              alt="Logo"
              style={{
                maxWidth: "180px", // Giriş yapıldığında biraz daha küçük
                marginTop: "20px",
                marginLeft: "-100px",
                borderRadius: "12px", // Giriş yapıldığında köşeler daha az yuvarlak
                boxShadow: "5px 15px 20px rgba(0, 0, 0, 0.2)", // Farklı bir gölge efekti
              }}
            />
          </Navbar.Brand>
        )}

        {/* Toggle Button for Mobile */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        {/* Menu Items */}
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            {!isLoggedIn && (
              <Nav.Link
                href="/"
                className="mx-3 text-dark"
                style={{ fontSize: "1.1rem", fontWeight: "500" }}
              >
                Home
              </Nav.Link>
            )}
            {!isLoggedIn && (
              <Nav.Link
                href="/about"
                className="mx-3 text-dark"
                style={{ fontSize: "1.1rem", fontWeight: "500" }}
              >
                About
              </Nav.Link>
            )}
            {!isLoggedIn && (
              <Nav.Link
                href="/solutions"
                className="mx-3 text-dark"
                style={{ fontSize: "1.1rem", fontWeight: "500" }}
              >
                Solutions
              </Nav.Link>
            )}
            <Nav.Link
              href="/pricing"
              className="mx-3 text-dark"
              style={{ fontSize: "1.1rem", fontWeight: "500" }}
            >
              Pricing
            </Nav.Link>
            <Nav.Link
              href="/help"
              className="mx-3 text-dark"
              style={{ fontSize: "1.1rem", fontWeight: "500" }}
            >
              Help
            </Nav.Link>
          </Nav>

          {/* Right Side: Login/Register or Logout */}
          {isLoggedIn ? (
            <Button
              variant="outline-dark"
              onClick={handleLogout}
              className="ml-3"
            >
              Logout
            </Button>
          ) : (
            <div className="d-flex align-items-center">
              <Button href="/login" className="me-2" variant="primary">
                Login
              </Button>
              <Button href="/register" className="ms-2" variant="outline-dark">
                Register
              </Button>
            </div>
          )}
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default NavigationBar;
