import React, { useState, useEffect } from "react";
import { ListGroup, Collapse, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaBars, FaUser, FaTags, FaUsers, FaEnvelope, FaAirbnb, FaMoneyBill } from "react-icons/fa"; // İkonlar eklendi
import "./Sidebar.css"; // Yeni modern CSS ekleyeceğiz

const Sidebar = () => {
  const [open, setOpen] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [isLoggedIn]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  if (!isLoggedIn) {
    return null;
  }

  return (
    <div className={`sidebar ${open ? "bg-dark expanded" : "bg-dark collapsed"}`} style={{marginTop:'130px'}}>
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="menu-collapse"
        aria-expanded={open}
        variant="primary"
        className="toggle-btn w-100 mb-2"
      >
        {open ? "Menü" : <FaBars />} {/* Eğer menü kapalıysa simge göster */}
      </Button>
      <Collapse in={open}>
        <div id="menu-collapse">
          <ListGroup>
            <ListGroup.Item action onClick={() => handleNavigation("/customers")}>
              <FaUsers className="me-2" /> Customer Management
            </ListGroup.Item>
            <ListGroup.Item action onClick={() => handleNavigation("/categories")}>
              <FaTags className="me-2" /> Category Management
            </ListGroup.Item>
            <ListGroup.Item action onClick={() => handleNavigation("/groups")}>
              <FaUsers className="me-2" /> Group Management
            </ListGroup.Item>
            <ListGroup.Item action onClick={() => handleNavigation("/messages")}>
              <FaEnvelope className="me-2" /> Message Management
            </ListGroup.Item>
            <ListGroup.Item action onClick={() => handleNavigation("/deposit")}>
              <FaMoneyBill className="me-2" /> Deposit
            </ListGroup.Item>
            <ListGroup.Item action onClick={() => handleNavigation("/accountSettings")}>
              <FaUser className="me-2" /> Account Settings
            </ListGroup.Item>
          </ListGroup>
        </div>
      </Collapse>
    </div>
  );
};


export default Sidebar;
